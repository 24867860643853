<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-06-18 17:34:43
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-18 12:11:49
 * @FilePath: /mediatom-web/src/viewsForManage/FunctionList/FlowStrategy/conponents/EditFlowStrategyModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="editFlowStrategyVisible"
    :title="(isAdd ? '添加' : '修改') + 'QPS策略'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="548px"
  >
    <div class="wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout" labelAlign="left">
        <!-- 策略类型 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择策略类型', trigger: 'change' }]"
          prop="strategyType"
          label="策略类型"
        >
          <a-select allowClear placeholder="请选择策略类型" v-model="query.strategyType" @change="changeStrategyType">
            <a-select-option :key="item.id" :value="item.id" v-for="item in strategyTypeList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 供应商 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择供应商', trigger: 'change' }]"
          prop="publisherId"
          v-if="+query.strategyType === 1 || +query.strategyType === 2"
          label="供应商"
        >
          <a-select
            allowClear
            placeholder="请选择供应商"
            showSearch
            :filter-option="filterOption"
            v-model="query.publisherId"
            @change="handleChangeSupplier">
            <a-select-option :key="item.id" :value="item.id" v-for="item in supplierList">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 广告位 -->
        <a-form-model-item
          :rules="[{ required: true, message: '请选择广告位', trigger: 'change' }]"
          prop="placeId"
          v-if="+query.strategyType === 2"
          label="广告位"
        >
          <a-select allowClear placeholder="请选择广告位" v-model="query.placeId">
            <a-select-option :key="item.id" :value="item.id" v-for="item in placeList">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- 编辑 -->
        <template v-if="!isAdd">
          <!-- 广告平台 -->
          <a-form-model-item
            :rules="[{ required: true, message: '请选择广告平台', trigger: 'change' }]"
            prop="platId"
            label="广告平台"
          >
            <a-select
              allowClear
              placeholder="请选择广告平台"
              v-model="query.platId"
              @change="handleChangePlat([$event])"
              :filter-option="filterOption"
              showSearch>
              <a-select-option :key="item.id" :value="'' + item.id" v-for="item in platList">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 广告源 -->
          <a-form-model-item
            :rules="[{ required: true, message: '请选择广告源', trigger: 'change' }]"
            prop="dspPlaceId"
            label="广告源"
          >
            <a-select
              allowClear
              placeholder="请选择广告源"
              v-model="query.dspPlaceId"
              :filter-option="filterOptionForSource"
              showSearch>
              <a-select-option :searchId="item.platPlaceId" :key="item.id" :value="item.id" v-for="item in dspList">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
        <!-- 添加 -->
        <template v-else>
          <!-- 广告平台 -->
          <a-form-model-item
            :rules="[{ required: true, message: '请选择广告平台', trigger: 'change' }]"
            prop="platIdList"
            label="广告平台"
          >
            <m-select-more
              style="width: 100%!important"
              v-model="query.platIdList"
              :allData="platList"
              :searchById="false"
              :hasIcon="false"
              :showId="false"
              label="广告平台"
              :showSelectedPart="true"
              :hasSearch="true"
              width="450px"
              :canSelectAll="true"
              :showLabel="false"
              @change="handleChangePlat"
              :hasPlease="true"
            />
          </a-form-model-item>
          <!-- 广告源 -->
          <a-form-model-item
            :rules="[{ required: true, message: '请选择广告源', trigger: 'change' }]"
            prop="dspPlaceIdList"
            label="广告源"
          >
            <m-select-more
              style="width: 100%!important"
              v-model="query.dspPlaceIdList"
              :allData="dspList"
              :searchById="true"
              :hasIcon="false"
              :showId="true"
              label="广告源"
              :showSelectedPart="true"
              :hasSearch="true"
              width="450px"
              :canSelectAll="true"
              :showLabel="false"
              :hasPlease="true"
            />
          </a-form-model-item>
        </template>
        <!-- 控制方式 -->
        <a-form-model-item prop="mode" label="控制方式">
          <a-radio-group v-model="query.mode">
            <a-radio :value="1"> 手动 </a-radio>
            <a-radio :value="2" v-if="+query.strategyType === 3"> 自动 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- QPS -->
        <a-form-model-item
          :rules="[{ validator: validatorQPS, trigger: 'blur' }]"
          prop="qpsLimit"
          label="QPS"
        >
          <a-input type="number" v-model.number.trim="query.qpsLimit" placeholder="请输入QPS"></a-input>
        </a-form-model-item>
        <!-- 请求比例 -->
        <a-form-model-item
          :rules="[{ validator: validatorRequestRatio, trigger: 'blur' }]"
          prop="requestRatio"
          label="请求比例"
        >
          <a-input type="number" v-model.number.trim="query.requestRatio" placeholder="请输入请求比例">
            <span class="percent-text" slot="suffix">%</span>
          </a-input>
        </a-form-model-item>
        <!-- 状态 -->
        <a-form-model-item prop="sts" label="状态">
          <a-radio-group v-model="query.sts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { strategyTypeList } from '../../list'
import { addFlowStrategy, updateFlowStrategy } from '@/apiForManage/functionList/flowStrategy'
import MixGetList from '@/mixins/getListForManage'
export default {
  mixins: [MixGetList],
  data () {
    return {
      strategyTypeList,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 15 }
      },
      queryTemp: {
        strategyType: undefined,
        publisherId: undefined,
        placeId: undefined,
        platId: undefined,
        platIdList: [],
        dspPlaceId: undefined,
        dspPlaceIdList: [],
        qpsLimit: undefined,
        requestRatio: undefined,
        sts: 'A',
        mode: 1
      },
      query: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    strategyInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    editFlowStrategyVisible: {
      handler (val) {
        if (val) {
          if (this.isAdd) {
            this.query = { ...this.queryTemp }
            this.getPlaceList()
            this.getDspList()
          } else {
            this.query = { ...this.strategyInfo }
            this.getPlaceList([this.strategyInfo.publisherId])
            this.getDspList([this.strategyInfo.platId])
            this.$set(this.query, 'strategyType', '' + this.strategyInfo.strategyType)
            this.$set(this.query, 'dspPlaceId', '' + this.strategyInfo.dspPlaceId)
            this.$set(this.query, 'platId', '' + this.strategyInfo.platId)
            this.$set(this.query, 'placeId', this.strategyInfo.placeId)
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    editFlowStrategyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  created () {
    this.getSupplierList()
    this.getPlatList()
  },
  methods: {
    changeStrategyType () {
      this.query.mode = 1
      console.log(123123)
    },
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    filterOptionForSource (input, option) {
      console.log(option)
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.data.attrs.searchId?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    validatorQPS (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('QPS必须大于等于0！'))
      } else {
        return callback()
      }
    },
    validatorRequestRatio (rule, value, callback) {
      if (value < 0) {
        return callback(new Error('请求比例必须大于等于0！'))
      } else if (value > 100) {
        return callback(new Error('请求比例必须小于等于100！'))
      } else {
        return callback()
      }
    },
    handleChangePlat (e) {
      this.query.dspPlaceId = undefined
      this.query.dspPlaceIdList = []
      this.getDspList(e.length ? e : [])
    },
    handleChangeSupplier (e) {
      this.query.placeId = undefined
      this.getPlaceList(e ? [e] : [])
    },
    handleCancel () {
      this.editFlowStrategyVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (!this.query.qpsLimit && !this.query.requestRatio) {
            this.$message.error('请输入QPS或请求比例！')
            return
          }
          if (this.isAdd) {
            const resp = await addFlowStrategy(this.query)
            if (resp.code === 200) {
              this.$message.success('新增成功！')
              this.editFlowStrategyVisible = false
              this.$emit('changeStrategy')
            }
          } else {
            const resp = await updateFlowStrategy(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功！')
              this.editFlowStrategyVisible = false
              this.$emit('changeStrategy')
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  height: 400px;
  overflow-y: auto;
}
</style>
